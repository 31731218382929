export const LEVEL_SAMPLE_PROPOSAL_URL =
  "https://ontrajectory.s3.amazonaws.com/level/level_proposal_sample.pdf";

export const LIST_PDF_PAGES = [
  {
    type: "growth",
    nodeIdCluster: [
      "reportPageCover",
      "reportPageIntroduction",
      "reportPageProposalGrowth",
      "reportPageProposalHowToGet",
      "reportPageProposalDisclosure",
    ],
  },
  {
    type: "income",
    nodeIdCluster: [
      "reportPageCover",
      "reportPageIntroduction",
      "reportPageProposalIncome",
      "reportPageProposalHowToGet",
      "reportPageProposalDisclosure",
    ],
  },
];
