import { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Flex, theme } from "antd";
import ChartComparisonGrowth from "../../../../../molecule/ChartComparisonGrowth";
import ReportHeader from "../../component/ReportHeader";

import StylesContainer from "./styles";

import { getLiveAssessment } from "../../../../../utils/request/regularApp";
import {
  getCurrencyValue,
  getPercentValue,
} from "../../../../../utils/helper/general";

import { ReactComponent as ElevateEliminate } from "./image/elevate_eliminate.svg";
import { ReactComponent as GrowthCheckmark } from "./image/growth_checkmark.svg";

const ReportPageProposalGrowth = ({
  personaName,
  productsList,
  proposalData,
  setLoading,
}) => {
  const { token } = theme.useToken();
  const [productsLiveAssessment, setProductsLiveAssessment] = useState();

  useEffect(() => {
    if (!proposalData) return;

    setLoading(true);

    getLiveAssessment({
      organization: proposalData.organization,
      objective: {
        ...proposalData,
        advisorFee: getPercentValue(proposalData.advisorFee),
        birthYear: proposalData.currentAge ? undefined : proposalData.birthYear,
        withdrawalLevel: getPercentValue(proposalData.withdrawalLevel ?? 0),
      },
      productId: proposalData.productId,
    }).then(liveAssessment => {
      setProductsLiveAssessment([liveAssessment]);

      // leave time for charts rendering
      setTimeout(() => setLoading(false), 1000);
    });
  }, [proposalData]);

  return (
    <StylesContainer id={"reportPageProposalGrowth"}>
      <Flex className={"page-container"} vertical>
        <ReportHeader personaName={personaName} />
        <h2>
          What we talked about: <b>Growth</b>
        </h2>
        <p style={{ margin: "16px 0 50px" }}>
          We all have different investment goals and objectives from maximizing
          retirement income, to funding a career change or to simply leave a
          financial legacy for the next generation. Income investors have a
          strong desire to create a consistent withdrawal amount from their
          investments. Let’s drive income differently.{" "}
        </p>

        <GrowthCheckmark />
        <Flex gap={60}>
          <div style={{ width: 750 }}>
            {productsLiveAssessment && (
              <ChartComparisonGrowth
                height={300}
                productsList={productsList}
                productsLiveAssessment={productsLiveAssessment}
                proposalData={proposalData}
              />
            )}
          </div>
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                backgroundColor: "transparent",
                height: 300,
                spacingTop: 0,
                type: "pie",
                width: 245,
              },
              tooltip: {
                outside: true,
                style: {
                  zIndex: 9999,
                },
              },
              title: {
                text: null,
              },
              credits: {
                enabled: false,
              },
              plotOptions: {
                pie: {
                  size: 158,
                  innerSize: "60%",
                  dataLabels: {
                    enabled: false,
                  },
                  showInLegend: true,
                  center: ["50%", "50%"],
                  borderColor: "#ffffff",
                  borderWidth: 2,
                },
              },
              legend: {
                enabled: true,
                layout: "vertical",
                align: "center",
                verticalAlign: "bottom",
                borderWidth: 0,
                labelFormatter: function () {
                  return `<div style="width:245px; white-space: normal;">${
                    this.y + "% " + this.name
                  }</div>`;
                },
                itemStyle: {
                  fontSize: 14,
                },
              },
              exporting: {
                buttons: {
                  contextButton: {
                    enabled: false,
                  },
                },
              },
              colors: token.chartColors,
              series: [
                {
                  colorByPoint: true,
                  data: productsList
                    .find(product => product.name === proposalData?.productName)
                    ?.assets?.map(it => ({
                      name: it.assetName,
                      y: it.investmentPercent,
                    })),
                  name: "Selected",
                  size: "100%",
                },
              ],
            }}
          />
        </Flex>

        <h4 style={{ marginTop: 100 }}>Your Selections</h4>
        <div className={"selections-container"} style={{ marginBottom: 60 }}>
          <div className={"value-container"}>
            <span className={"label"}>Investment Objective</span>
            <p className={"value"} style={{ textTransform: "capitalize" }}>
              {proposalData?.investmentObjective}
            </p>
          </div>
          <div className={"value-container"}>
            <span className={"label"}>Length of Investment</span>
            <p className={"value"}>
              {proposalData?.investmentDuration} year(s)
            </p>
          </div>
          <div className={"value-container"}>
            <span className={"label"}>Risk Tolerance</span>
            <p className={"value"} style={{ textTransform: "capitalize" }}>
              {proposalData?.riskTolerance}
            </p>
          </div>
          <div className={"value-container"}>
            <span className={"label"}>Initial Investment</span>
            <p className={"value"}>
              {getCurrencyValue(proposalData?.investmentAmount)}
            </p>
          </div>
          <div className={"value-container"}>
            <span className={"label"}>Advisor Fee</span>
            <p className={"value"}>
              {getPercentValue(proposalData?.advisorFee)}
            </p>
          </div>
        </div>

        <Flex align={"center"} gap={90}>
          <Flex flex={"706px"} vertical>
            <h4>Planning Center</h4>
            <p>
              The goal of financial planning is quite simple– to help you live
              the best life you can with the money that you have accumulated, or
              intend to accumulate.
            </p>
            <p>
              By working through important but often ignored questions, your
              advisor will gain valuable insight into what is most important to
              you. Then, they can help provide clarity and confidence to live
              your financial life in alignment with your highest priorities.
            </p>
            <p>
              Integrating your faith and wealth can finally create the alignment
              that has been missing. To steward your money well, you deserve an
              advisor who can translate your unique personal needs into an
              actionable strategy.
            </p>
          </Flex>

          <ElevateEliminate />
        </Flex>
      </Flex>
    </StylesContainer>
  );
};

export default ReportPageProposalGrowth;
