import cover from "../pdf/image/preview/cover.png";
import facts_sheet from "../pdf/image/preview/facts_sheet.png";
import introduction from "../pdf/image/preview/introduction.png";
import ipq from "../pdf/image/preview/ipq.png";
import milestones from "../pdf/image/preview/milestones.png";
import perspectives from "../pdf/image/preview/perspectives.png";
import priorities from "../pdf/image/preview/priorities.png";
import proposal_product_selection from "../pdf/image/preview/proposal_product_selection.png";

export const DEFAULT_PROPOSAL_INITIALIZATION_DATA = {
  contributions: 12000,
  currentAge: 40,
  currentSpouseAge: 0,
  householdIncome: 0,
  investmentAmount: 100000,
  investmentDuration: 20,
  investmentObjective: "growth",
  yearToStartWithdrawals: 10,
  organization: "OneAscent",
  riskTolerance: "Growth",
  withdrawalLevel: "5%",
};

export const ELEVATE_STORIES_LINK =
  "https://ontrajectory.s3.amazonaws.com/oneascent/factsheets/OneAscent_Investments_Align_Elevate_Stories_Q3_2023.pdf";

export const LIST_PDF_PAGES = [
  {
    key: "cover_page",
    label: "Cover Page",
    nodeId: "reportPageCover",
    previewImage: cover,
  },
  {
    key: "introduction",
    label: "Introduction",
    nodeId: "reportPageIntroduction",
    previewImage: introduction,
  },
  {
    key: "proposal_product_selection",
    label: "Proposal & Product Selection",
    nodeIdCluster: [
      "reportPageProposalGrowth",
      "reportPageProposalIncome",
      "reportPageProposalHowToGet",
    ],
    previewImage: proposal_product_selection,
  },
  {
    key: "ipq",
    label: "IPQ",
    nodeIdCluster: ["reportPageIpq1", "reportPageIpq2"],
    previewImage: ipq,
  },
  {
    key: "perspectives",
    label: "Perspectives",
    nodeIdCluster: [
      "reportPagePersonalityIntro",
      "reportPagePersonalityUser",
      "reportPagePersonalitySpouse",
      "reportPagePersonalityCouple",
      "reportPagePersonalityCommonBlind",
      "reportPagePersonalityUnique",
    ],
    previewImage: perspectives,
  },
  {
    key: "priorities",
    label: "Priorities",
    nodeId: "reportPagePriorities",
    previewImage: priorities,
  },
  {
    key: "milestones",
    label: "Milestones",
    nodeId: "reportPageMilestones",
    previewImage: milestones,
  },
  {
    key: "fact_sheets",
    label: "Fact Sheets & Disclosures",
    nodeIdCluster: [
      "reportPageProposalFactSheet",
      "reportPageProposalDisclosure",
    ],
    previewImage: facts_sheet,
  },
];

export const LIST_PERSPECTIVES_PROFILE = [
  {
    description: `<b>PRESENT</b> perspectives are available and ready. They are comfortable both building a comfortable nest egg and then splurging on the things that matter most. They bring both levity and responsibility to their relationships, and they are focused on the people in front of them.<br/><br/><b>PRESENT</b> perspectives may find their financial situation too static, as money tends to flow in and then eventually flow back out without substantial growth or giving.`,
    name: "Present",
    spending: 70,
    giving: 20,
    saving: 70,
    investing: 20,
    uniqueTendenciesCopy:
      "The Present partner, [userName], helps the family stay grounded.",
  },
  {
    description: `<b>ABUNDANT</b> perspectives are not worried about from where their next dollar will come. They are naturals at enjoying what they have and helping others do the same. They prioritize generosity, but they don't believe this must come at the expense of a quality lifestyle.<br/><br/><b>ABUNDANT</b> perspectives may not sufficiently prepare for their future without committed intentionality.`,
    name: "Abundant",
    spending: 70,
    giving: 70,
    saving: 20,
    investing: 20,
    uniqueTendenciesCopy:
      "The Abundant partner, [userName], helps the family stay grateful.",
  },
  {
    description: `<b>ENTERPRISING</b> perspectives want to live life to the fullest, both in the short and long term. They are prone to think about what could be around the corner. One moment, this may be plotting a car purchase or vacation plan, and the next they're checking on their college or retirement savings.<br/><br/><b>ENTERPRISING</b> perspectives may be prone to riskier than healthy financial behavior, leaving little wiggle room to help when needs arise in the present.`,
    name: "Enterprising",
    spending: 70,
    giving: 20,
    saving: 20,
    investing: 70,
    uniqueTendenciesCopy:
      "The Enterprising partner, [userName], helps the family stay creative.",
  },
  {
    description: `<b>CONTENT</b> perspectives lack for nothing. They treat money gently and prudently and know they will have enough, keeping what they need and giving away the rest. They are not impressed with their neighbor's new car or their coworker's 401(k) balance.<br/><br/><b>CONTENT</b> perspectives may be too conservative or apprehensive financially, missing out on the rewards that accompany aggressive investments or worthwhile purchases.`,
    name: "Content",
    spending: 20,
    giving: 70,
    saving: 70,
    investing: 20,
    uniqueTendenciesCopy:
      "The Content partner, [userName], helps the family stay humble.",
  },
  {
    description: `<b>PREPARED</b> perspectives refuse to be caught off-guard. They make sure there's enough money to go around no matter what, both next week and next decade. They are diligent, reliable, and focused on their family and friends.<br/><br/><b>PREPARED</b> perspectives may prioritize accumulation too much, missing out on how their wealth can be used for benevolence and making memories.`,
    name: "Prepared",
    spending: 20,
    giving: 20,
    saving: 70,
    investing: 70,
    uniqueTendenciesCopy:
      "The Prepared partner, [userName], helps the family stay vigilant.",
  },
  {
    description: `<b>FUTURISTIC</b> perspectives see a better world around the corner. They use their money to help build it, by investing in the causes that move them and investing in their financial future. They are patient and love to watch things grow, both their communities and their accounts.<br/><br/><b>FUTURISTIC</b> perspectives may look so far down the road that they miss both the beautiful scenery and caution signs right in front of them.`,
    name: "Futuristic",
    spending: 20,
    giving: 70,
    saving: 20,
    investing: 70,
    uniqueTendenciesCopy:
      "The Futuristic partner, [userName], helps the family stay hopeful.",
  },
  {
    description: `<b>RESPONSIVE</b> perspectives can read the room. They refuse to commit to where the next dollar will go until they know where the next dollar is needed. They diversify their approach day-to-day, all the while ask questions and waiting to change course until there's a clear answer.<br/><br/><b>RESPONSIVE</b> perspectives can be so good at spending, saving, giving, and investing in moderation that they try to do everything at once, which can make their financial situation a mile wide but an inch deep.`,
    name: "Responsive",
    spending: 45,
    giving: 45,
    saving: 45,
    investing: 45,
    uniqueTendenciesCopy:
      "The Responsive partner, [userName], helps the family stay diligent.",
  },
];

export const ONEASCENT_RISK_TYPES = [
  { value: "equity", label: "Equity" },
  { value: "growth", label: "Growth" },
  { value: "moderate", label: "Moderate" },
  { value: "conservative", label: "Conservative" },
  { value: "preservation", label: "Preservation" },
];

export const PORTFOLIO_X_RAY_LIST = {
  equity: {
    descriptions: `OneAscent’s values-aligned process has the unique ability to identify, exclude, and remove holdings
      from your portfolio in real time as your portfolio is constructed. This X-Ray report illustrates our ability to
      uncover and remove positions that should be excluded. Our investment process uses appropriate benchmarks to set
      client expectations. If a client simply owned the benchmarks their values would not be aligned with their
      investments, as shown here.`,
    tableData: [
      {
        indexName: "MSCI ACWI Ex USA NR USD",
        etf: "ACWX",
        weight: "40%",
        weighted: "11.1%",
        company: "Shell PLC",
        issue: "Toxic Emissions & Waste",
      },
      {
        indexName: "Russell 2500 TR USD",
        etf: "SMMD",
        weight: "30%",
        weighted: "4%",
        company: "Avery Dennison Corp",
        issue: "Alcohol Industry Supply Chain",
      },
      {
        indexName: "S&P 500 TR USD",
        etf: "SPY",
        weight: "30%",
        weighted: "13.2%",
        company: "Apple Inc",
        issue: "Supply Chain Labor Standards",
      },
      {
        indexName: "Total Portfolio",
        etf: "",
        weight: "100%",
        weighted: "9.6%",
        company: "",
        issue: "",
      },
    ],
    typicalPortfolioNotAlignedValue: 9.6,
    pieChartData: [
      {
        name: "ACWX",
        y: 40,
      },
      {
        name: "SMMD",
        y: 30,
      },
      {
        name: "SPY",
        y: 30,
      },
    ],
  },
  growth: {
    descriptions: `OneAscent’s values-aligned process has the unique ability to identify, exclude, and remove holdings
      from your portfolio in real time as your portfolio is constructed. This X-Ray report illustrates our ability to
      uncover and remove positions that should be excluded. Our investment process uses appropriate benchmarks to set
      client expectations. If a client simply owned the benchmarks their values would not be aligned with their
      investments, as shown here.`,
    tableData: [
      {
        indexName: "Bloomberg US Agg Bond TR USD",
        etf: "AGG",
        weight: "20%",
        weighted: "1.8%",
        company: "The Boeing Co",
        issue: "Product Safety & Quality",
      },
      {
        indexName: "MSCI ACWI Ex USA NR USD",
        etf: "ACWX",
        weight: "32%",
        weighted: "11.1%",
        company: "Shell PLC",
        issue: "Toxic Emissions & Waste",
      },
      {
        indexName: "Russell 2500 TR USD",
        etf: "SMMD",
        weight: "24%",
        weighted: "4%",
        company: "Avery Dennison Corp",
        issue: "Alcohol Industry Supply Chain",
      },
      {
        indexName: "S&P 500 TR USD",
        etf: "SPY",
        weight: "24%",
        weighted: "13.2%",
        company: "Apple Inc",
        issue: "Supply Chain Labor Standards",
      },
      {
        indexName: "Total Portfolio",
        etf: "",
        weight: "100%",
        weighted: "8%",
        company: "",
        issue: "",
      },
    ],
    typicalPortfolioNotAlignedValue: 8.0,
    pieChartData: [
      {
        name: "AGG",
        y: 20,
      },
      {
        name: "ACWX",
        y: 32,
      },
      {
        name: "SMMD",
        y: 24,
      },
      {
        name: "SPY",
        y: 24,
      },
    ],
  },
  moderate: {
    descriptions: `OneAscent’s values-aligned process has the unique ability to identify, exclude, and remove holdings
      from your portfolio in real time as your portfolio is constructed. This X-Ray report illustrates our ability to
      uncover and remove positions that should be excluded. Our investment process uses appropriate benchmarks to set
      client expectations. If a client simply owned the benchmarks their values would not be aligned with their
      investments, as shown here.`,
    typicalPortfolioNotAlignedValue: 6.5,
    tableData: [
      {
        indexName: "Bloomberg US Agg Bond TR USD",
        etf: "AGG",
        weight: "40%",
        weighted: "1.8%",
        company: "The Boeing Co",
        issue: "Product Safety & Quality",
      },
      {
        indexName: "MSCI ACWI Ex USA NR USD",
        etf: "ACWX",
        weight: "24%",
        weighted: "11.1%",
        company: "Shell PLC",
        issue: "Toxic Emissions & Waste",
      },
      {
        indexName: "Russell 2500 TR USD",
        etf: "SMMD",
        weight: "18%",
        weighted: "4%",
        company: "Avery Dennison Corp",
        issue: "Alcohol Industry Supply Chain",
      },
      {
        indexName: "S&P 500 TR USD",
        etf: "SPY",
        weight: "18%",
        weighted: "13.2%",
        company: "Apple Inc",
        issue: "Supply Chain Labor Standards",
      },
      {
        indexName: "Total Portfolio",
        etf: "",
        weight: "100%",
        weighted: "6.5%",
        company: "",
        issue: "",
      },
    ],
    pieChartData: [
      {
        name: "AGG",
        y: 40,
      },
      {
        name: "ACWX",
        y: 24,
      },
      {
        name: "SMMD",
        y: 18,
      },
      {
        name: "SPY",
        y: 18,
      },
    ],
  },
  conservative: {
    descriptions: `OneAscent’s values-aligned process has the unique ability to identify, exclude, and remove holdings
      from your portfolio in real time as your portfolio is constructed. This X-Ray report illustrates our ability to
      uncover and remove positions that should be excluded. Our investment process uses appropriate benchmarks to set
      client expectations. If a client simply owned the benchmarks their values would not be aligned with their
      investments, as shown here.`,
    tableData: [
      {
        indexName: "Bloomberg US Agg Bond TR USD",
        etf: "AGG",
        weight: "60%",
        weighted: "1.8%",
        company: "The Boeing Co",
        issue: "Product Safety & Quality",
      },
      {
        indexName: "MSCI ACWI Ex USA NR USD",
        etf: "ACWX",
        weight: "16%",
        weighted: "11.1%",
        company: "Shell PLC",
        issue: "Toxic Emissions & Waste",
      },
      {
        indexName: "Russell 2500 TR USD",
        etf: "SMMD",
        weight: "12%",
        weighted: "4%",
        company: "Avery Dennison Corp",
        issue: "Alcohol Industry Supply Chain",
      },
      {
        indexName: "S&P 500 TR USD",
        etf: "SPY",
        weight: "12%",
        weighted: "13.2%",
        company: "Apple Inc",
        issue: "Supply Chain Labor Standards",
      },
      {
        indexName: "Total Portfolio",
        etf: "",
        weight: "100%",
        weighted: "4.9%",
        company: "",
        issue: "",
      },
    ],
    typicalPortfolioNotAlignedValue: 4.9,
    pieChartData: [
      {
        name: "AGG",
        y: 60,
      },
      {
        name: "ACWX",
        y: 16,
      },
      {
        name: "SMMD",
        y: 12,
      },
      {
        name: "SPY",
        y: 12,
      },
    ],
  },
  preservation: {
    descriptions: `OneAscent’s values-aligned process has the unique ability to identify, exclude, and remove holdings
      from your portfolio in real time as your portfolio is constructed. This X-Ray report illustrates our ability to
      uncover and remove positions that should be excluded. Our investment process uses appropriate benchmarks to set
      client expectations. If a client simply owned the benchmarks their values would not be aligned with their
      investments, as shown here.`,
    tableData: [
      {
        indexName: "Bloomberg US Agg Bond TR USD",
        etf: "AGG",
        weight: "80%",
        weighted: "1.8%",
        company: "The Boeing Co",
        issue: "Product Safety & Quality",
      },
      {
        indexName: "MSCI ACWI Ex USA NR USD",
        etf: "ACWX",
        weight: "8%",
        weighted: "11.1%",
        company: "Shell PLC",
        issue: "Toxic Emissions & Waste",
      },
      {
        indexName: "Russell 2500 TR USD",
        etf: "SMMD",
        weight: "6%",
        weighted: "4%",
        company: "Avery Dennison Corp",
        issue: "Alcohol Industry Supply Chain",
      },
      {
        indexName: "S&P 500 TR USD",
        etf: "SPY",
        weight: "6%",
        weighted: "13.2%",
        company: "Apple Inc",
        issue: "Supply Chain Labor Standards",
      },
      {
        indexName: "Total Portfolio",
        etf: "",
        weight: "100%",
        weighted: "3.4%",
        company: "",
        issue: "",
      },
    ],
    typicalPortfolioNotAlignedValue: 3.4,
    pieChartData: [
      {
        name: "AGG",
        y: 80,
      },
      {
        name: "ACWX",
        y: 8,
      },
      {
        name: "SMMD",
        y: 6,
      },
      {
        name: "SPY",
        y: 6,
      },
    ],
  },
};

export const getListUseOfMoney = (partnerA = "", partnerB = "") => [
  {
    accentColor: "#15284B",
    descriptionBlindSpots:
      "In general, Spending may not be this family unit's first instinct. The couple may require devoted intentionality to enjoy the wealth they have been given. It may seem natural for one or both partners to view Spending as short-sighted or wasteful. Nevertheless, Spending is a vital, right use of money in many situations, helping us thrive in the time we have together. This is a Use to monitor as the couple forms a financial plan.",
    descriptionCommonGround:
      "The couple's responses indicate that, together, they naturally enjoy the fruits of their labor. Each person may have individual hobbies or interests, but there remains a deep commitment to quality time and shared experiences. When the couple Spends money together, it can be a powerful, beautiful means of pursuing connection and growth. Spending may be an easier fit in the couple's financial plan than other Uses.",
    descriptionUniqueTendencies: `
      It's possible the couple has noticed that ${partnerA} has a greater inclination to Spend than ${partnerB}. Here are three quick points for consideration.
      <ul>
        <li>${partnerA} can help the family slow down and savor the good gifts they've been given.</li>
        <li>${partnerB} can help the family determine the fine line between enjoyment and over-indulgence.</li>
        <li>The couple may consider having a 'fun money' budget for each person and sticking to it</li>
      </ul>`,
    type: "spending",
    value: "Spending",
  },
  {
    accentColor: "#2FAFE4",
    descriptionBlindSpots:
      "In general, Giving may not be this family unit's first instinct. The couple may require devoted intentionality to share the wealth they have been given. It may seem natural for one or both partners to view Giving as unwise or imprudent. Nevertheless, Giving is a vital, right use of money in many situations, empowering us to empower those around us. This is a Use to monitor as the couple forms a financial plan.",
    descriptionCommonGround:
      "The couple's responses indicate that, together, they naturally share the fruits of their labor with others. Each person may have their own passions or projects, but there remains a deep commitment to generosity and bettering the world. When the couple Gives money together, it can be a powerful, beautiful means of pursuing flourishing and impact. Giving may be an easier fit in the couple's financial plan than other Uses.",
    descriptionUniqueTendencies: `
      It's possible the couple has noticed that ${partnerA} has a greater inclination to Give than ${partnerB}. Here are three quick points for consideration.
      <ul>
        <li>${partnerA} can help the family envision the freedom and flourishing that generosity brings.</li>
        <li>${partnerB} can help the family determine the fine line between charitability and irresponsibility.</li>
        <li>The couple may consider picking a list together of their most important causes and charities and sticking to it.</li>
      </ul>`,
    type: "giving",
    value: "Giving",
  },
  {
    accentColor: "#7C98AB",
    descriptionBlindSpots:
      "In general, Saving may not be this family unit's first instinct. The couple may require devoted intentionality to preserve the wealth they have been given. It may seem natural for one or both partners to view Saving as too conservative or miserly. Nevertheless, Saving is a vital, right use of money in many situations, keeping us from debt and stress when emergencies arise. This is a Use to monitor as the couple forms a financial plan.",
    descriptionCommonGround:
      "The couple's responses indicate that, together, they naturally preserve the fruits of their labor. Each person may have their own goals or concerns, but there remains a deep commitment to security and stability. When the couple Saves money together, it can be a powerful, beautiful means of pursuing freedom and peace. Saving may be an easier fit in the couple's financial plan than other Uses.",
    descriptionUniqueTendencies: `It's possible the couple has noticed that ${partnerA} has a greater inclination to Save than ${partnerB}. Here are three quick points for consideration.
    <ul>
      <li>${partnerA} can help the family reckon with the reality of situations that require preparation.</li>
      <li>${partnerB} can help the family determine the fine line between caution and fearfulness.</li>
      <li>The couple may consider establishing an agreed upon emergency fund limit and sticking to it.</li>
    </ul>`,
    type: "saving",
    value: "Saving",
  },
  {
    accentColor: "#BDA568",
    descriptionBlindSpots:
      "In general, Investing may not be this family unit's first instinct. The couple may require devoted intentionality to grow the wealth they have been given. It may seem natural for one or both partners to view Investing as too risky or ambitious. Nevertheless, Investing is a vital, right use of money in many situations, enabling us to accomplish our future goals. This is a Use to monitor as the couple forms a financial plan.",
    descriptionCommonGround:
      "The couple's responses indicate that, together, they naturally grow the fruits of their labor. Each person may have their own appetites for risk or timing, but there remains a deep commitment to stewarding the family's future well. When the couple Invests money together, it can be a powerful, beautiful means of pursuing opportunity and readiness. Investing may be an easier fit in the couple's financial plan than other Uses.",
    descriptionUniqueTendencies: `It's possible the couple has noticed that ${partnerA} has a greater inclination to Invest than ${partnerB}. Here are three quick points for consideration.
      <ul>
        <li>${partnerA} can help the family dream about the future impact of today's decisions.</li>
        <li>${partnerB} can help the family determine the fine line between readiness and overfunding.</li>
        <li>The couple may consider dollar-cost averaging an agreed upon amount into respective accounts each month and sticking to it.</li>
      </ul>`,
    type: "investing",
    value: "Investing",
  },
];
